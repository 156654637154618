import React, { useCallback, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { IProps } from './types'
import Button from 'components/Button'
import api from 'services/api'

const DeleteModal: React.FC<IProps> = ({ isOpen, row, onClose, onDelete }) => {
  const toast = useToast()
  const cancelRef = useRef<null>(null)

  // const onDelete = useCallback(() => {
  //   console.log('quero deletar')
  //   api
  //     .delete(`/sceneries/${row.id}/${row.type}/delete`)
  //     .then(() => {
  //       onClose()
  //       toast({
  //         title: 'Cenário excluido com sucesso!',
  //         status: 'success',
  //         isClosable: true,
  //       })
  //     })
  //     .catch((error: any) => {
  //       toast({
  //         title: `Erro ao deletar o capitão: ${error.message} `,
  //         status: 'error',
  //         isClosable: true,
  //       })
  //     })
  // }, [row.id, toast, onClose, row.type])

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxWidth="600px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deseja mesmo excluir essa condição comercial?
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack alignItems="flex-start" fontSize="md" spacing={0.5}>
                {/* <Text marginBottom={'40px'} fontSize={'13pt'}>
                  {' '}
                  Cenário: {row.name}
                </Text> */}
                <Text color="#D20404">
                  Ao confirmar a exclusão, este item será removido de forma
                  imediata e irreversível.
                </Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  containerStyle={{ backgroundColor: '#D20404' }}
                  onClick={onDelete}
                >
                  Deletar
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteModal
