import { AxiosResponse } from 'axios'
import api from '../../services/api'

export const verifyEditedStatus = async (sceneryId: number) => {
  const url = 'sceneries/verify-edited-status'
  return api
    .post(url, { sceneryId })
    .then((response: AxiosResponse<any>) => {
      return response.data.data.result[0].edited_status
      
    })
    .catch(() => {
      return []
    })
}
