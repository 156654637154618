import { useState } from 'react'
import Body from './Body'
import Header from './Header'
import { useToast } from '@chakra-ui/react'
import api from 'services/api'

const RelatividadeCanais: React.FC<any> = () => {
  const toast = useToast()
  const [rows, setRows] = useState<any[]>([])
  const [clusterId, setClusterId] = useState('0')
  const [changedRows, setChangedRows] = useState<any[]>([])

  console.log(changedRows, 'changedRows')

  const handleSave = async () => {
    try {
      changedRows.forEach(async (row) => {
        const url = `/relativity-channels/${clusterId}/${row.id}/relativity`

        const req = {
          captain_channel_id: row.captain_channel_id,
          relativity: +row.relativity,
        }
        await api.put(url, req)
      })
      setChangedRows([])
      toast({
        title: 'Atualização efetuada com sucesso',
        status: 'success',
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Erro, tente novamente.' + error,
        status: 'error',
        isClosable: true,
      })
      console.error('Erro ao salvar os dados:', error)
    }
  }

  return (
    <>
      <Header
        setRows={setRows}
        setClusterId={setClusterId}
        clusterId={clusterId}
        handleSave={handleSave}
      />
      <Body
        rows={rows}
        setRows={setRows}
        clusterId={clusterId}
        setChangedRows={setChangedRows}
        changedRows={changedRows}
      />
    </>
  )
}

export default RelatividadeCanais
