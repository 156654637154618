import { useState } from 'react'

import Body from './Body'
import Header from './Header'
import api from 'services/api'
import { useToast } from '@chakra-ui/react'

const RelatividadeDeProduto: React.FC<any> = () => {
  const toast = useToast()
  const [rows, setRows] = useState<any[]>([])
  const [captain, setCaptain] = useState('')
  const [changedRows, setChangedRows] = useState<any[]>([])

  const handleSave = async () => {
    try {
      changedRows.forEach(async (row) => {
        const url = `/relativity-sku/relativity/${row.sku}/${row.relativity}`

        const req = {
          sku: row.sku,
          relativity: +row.relativity,
        }
        await api.put(url, req)
      })
      setChangedRows([])
      toast({
        title: 'Atualização efetuada com sucesso',
        status: 'success',
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Erro, tente novamente.' + error,
        status: 'error',
        isClosable: true,
      })
      console.error('Erro ao salvar os dados:', error)
    }
  }

  return (
    <>
      <Header
        setRows={setRows}
        setCaptain={setCaptain}
        handleSave={handleSave}
      />
      <Body
        rows={rows}
        setRows={setRows}
        captain={captain}
        setChangedRows={setChangedRows}
        changedRows={changedRows}
      />
    </>
  )
}

export default RelatividadeDeProduto
