import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Flex, Input, Text, useDisclosure, useToast } from '@chakra-ui/react'

import api from 'services/api'
import Button from 'components/Button'
import LoadingModalButton from 'components/LoadingModalButton'
import moment from 'moment'
import ModalDraggable from 'components/Modal'

interface Props {
  refreshVerifyStatus: boolean
}

const SendToApproveButton: React.FC<Props> = ({ refreshVerifyStatus }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id, type } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [initialValidityDate, setInitialValidityDate] = useState<string>('')
  const [finalValidityDate, setFinalValidityDate] = useState<string>('')

  const onConfirm = useCallback(() => {
    const url = '/requests/create'
    const req = {
      id: +id!,
      type,
      initialValidityDate,
      finalValidityDate,
    }

    return api
      .post(url, req)
      .then(() => {
        navigate('/pricebase')
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Erro ao enviar, tente novamente.',
        })
      })
  }, [id, navigate, toast, type, initialValidityDate, finalValidityDate])

  const onChangeInitial = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value
    const inputDate = moment(date, 'YYYY-MM-DD')
    const currentDate = moment()

    if (inputDate.isBefore(currentDate, 'day')) {
      toast({
        status: 'error',
        title: 'A data deve ser posterior à data de hoje',
      })
      return
    }

    setInitialValidityDate(date)
  }

  const onChangeFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value
    const inputDate = moment(date, 'YYYY-MM-DD')

    if (inputDate.isBefore(moment(initialValidityDate), 'day')) {
      toast({
        status: 'error',
        title: 'A data final deve ser posterior à data inicial',
      })
      return
    }

    setFinalValidityDate(date)
  }

  return (
    <>
      <Button disable={!refreshVerifyStatus} onClick={onOpen}>
        Enviar Para Aprovação
      </Button>
      <ModalDraggable
        w="32rem"
        isOpen={isOpen}
        onClose={onClose}
        title="Enviar Para Aprovação"
        body={
          <div style={{ margin: 20 }}>
            <Text marginBottom={2} fontSize={14} fontWeight={'bold'}>
              Data de Vigência
            </Text>
            <Flex>
              <div>
                <Text marginBottom={2} fontSize={14}>
                  Ínicio
                </Text>
                <Input
                  w="12rem"
                  type="date"
                  value={initialValidityDate}
                  onChange={onChangeInitial}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
              <div style={{ marginLeft: 15 }}>
                <Text marginBottom={2} fontSize={14}>
                  Fim
                </Text>
                <Input
                  w="12rem"
                  type="date"
                  value={finalValidityDate}
                  onChange={onChangeFinal}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </Flex>
          </div>
        }
        footer={
          <Flex w="100%" justify="space-between">
            <Button onClick={onClose}>Cancelar</Button>
            <LoadingModalButton
              disable={!initialValidityDate || !finalValidityDate}
              promise={onConfirm}
              message="Enviando, por favor aguarde..."
            >
              Confirmar
            </LoadingModalButton>
          </Flex>
        }
      />
    </>
  )
}

export default SendToApproveButton
