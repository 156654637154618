import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'

import { UserContext } from 'state/user-context'
import api from 'services/api'

interface IProps {
  row: {
    month: string
    year: number
    average_price: string
    monthly_billing: string
    id: any
  }
  param: string
  value: any
  // onUpdate: () => void; // Função de callback para atualizar a linha
  onUpdate: (rowId: string, row: any, param: string) => void
}

const Input: React.FC<IProps> = ({ row, param, onUpdate, value }) => {
  const { userGoalsPlan } = useContext(UserContext)

  // Função para formatar valores no padrão brasileiro
  const formatValue = useCallback((rawValue: any) => {
    if (!rawValue || isNaN(Number(String(rawValue).replace(',', '.')))) {
      return '0,00' // Valor padrão para entrada vazia ou inválida
    }

    const numericValue = parseFloat(String(rawValue).replace(',', '.'))

    const value = Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericValue)

    return value
  }, [])

  const [newValue, setNewValue] = useState<string>(() => formatValue(value))

  useEffect(() => {
    setNewValue(formatValue(value))
  }, [value, formatValue])

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value) // Permite ao usuário digitar livremente
  }, [])

  // Formata e envia o valor quando o campo perde o foco
  // const onBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
  //   const rawValue = e.target.value.replaceAll('.', '').replace(',', '.');
  //   const numericValue = parseFloat(rawValue);

  //   if (!isNaN(numericValue)) {
  //     await api.put('goals-plan/update', {
  //       month: row.month,
  //       year: row.year,
  //       data: { [param]: numericValue },
  //     });
  //     onUpdate();
  //   }

  //   // Atualiza o estado local com o valor formatado
  //   setNewValue(formatValue(rawValue));
  // };

  const onBlur = useCallback(() => {
    console.log(newValue, 'newValue')
    const rawValue = newValue.replaceAll('.', '').replace(',', '.')
    const numericValue = parseFloat(rawValue)
    const updatedRow = { ...row, [param]: numericValue }
    onUpdate(row.id, updatedRow, param)
  }, [param, row, onUpdate, newValue])

  return (
    <InputGroup w="18rem">
      <InputLeftAddon bgColor="#BEE3F8" fontSize="1rem !important">
        R$
      </InputLeftAddon>
      <ChakraInput
        disabled={!userGoalsPlan.write || row.month === 'TOTAL'}
        type="text"
        value={newValue}
        onBlur={onBlur}
        onChange={onChange}
        borderColor="#BEE3F8"
      />
    </InputGroup>
  )
}

export default Input
