import { HStack } from '@chakra-ui/react'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import Card from './Card'
import IData from './types/IData'
import { IFilters } from '../Table/types'

interface IOption {
  value: string
}

interface IProps {
  getBigData: boolean
  ids: number[]
  typeView: string
  filter: IFilters
  refreshBigNumbers: boolean
  showBigNumbers: boolean
  setShowBigNumbers: Dispatch<SetStateAction<boolean>>
}

const Cards: React.FC<IProps> = ({
  getBigData,
  ids,
  typeView,
  filter,
  refreshBigNumbers,
  showBigNumbers,
  setShowBigNumbers,
}) => {
  const { id } = useParams()
  const [data, setData] = useState<IData[]>([])

  const getData2 = useCallback(() => {
    const request: { [key: string]: string[] } = {}

    Object.keys(filter).forEach((key) => {
      request[key] =
        (filter[key] as IOption[])?.map((v: IOption) => v.value) || []
    })

    api
      .post(`/sceneries/new-big-numbers`, {
        sceneryId: id,
        ids: [], // you can pass ids if necessary
        typeView,
        filter: request,
      })
      .then((res: any) => {
        const result = res.data.data.rows[0]
        setData([
          {
            title: 'Preço Médio Histórico',
            main: result?.preco_medio_historico,
            volume: result?.volume_historico,
            faturamento: result?.faturamento_bruto_historico,
            margem1: result?.faturamento_liquido_historico,
            margem2: result?.margem_bruta_historico,
            margem3: result?.margem_bruta_historico,
            margem4: result?.margem_bruta_percent,
            color: 'green',
          },
          {
            title: 'Preço Médio Atual',
            main: result?.preco_medio_atual,
            volume: result?.volume_atual,
            faturamento: result?.faturamento_bruto_atual,
            margem1: result?.faturamento_liquido_atual,
            margem2: 0,
            margem3: result?.margem_bruta_atual,
            margem4: result?.margem_percent_atual,
            color: 'purple',
          },
          {
            title: 'Preço Médio Simulado',
            main: result?.preco_medio_projetado,
            volume: result?.volume_projetado,
            faturamento: result?.faturamento_bruto_projetado,
            margem1: result?.faturamento_liquido_projetado,
            margem2: 2,
            margem3: result?.margem_bruta_projetado,
            margem4: result?.margem_percent_projetado,
            indice: result?.indice_projetado,
            color: 'yellow',
          },
          {
            title: 'Preço Médio Final',
            main: result?.preco_medio_final,
            volume: result?.volume_final,
            faturamento: result?.faturamento_bruto_final,
            margem1: result?.faturamento_liquido_final,
            margem2: 2,
            margem3: result?.margem_bruta_final,
            margem4: result?.margem_percent_final,
            indice: result?.indice,
            color: 'blue',
          },
        ])
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, typeView, filter, refreshBigNumbers])

  useEffect(() => {
    getData2()
  }, [getData2])  

  return (
    <HStack
      w="100%"
      mb="0rem"
      flexDirection={'column'}
      alignItems={'flex-start'}
    >
      {showBigNumbers ? (
        <HStack w="100%" spacing="1rem" mb="0.2rem">
          {data.map((info) => (
            <Card key={info.title} info={info} />
          ))}
        </HStack>
      ) : null}
      {showBigNumbers ? (
        <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', zIndex: 99}}> 
          <FaAngleUp size={20} onClick={() => setShowBigNumbers(false)} />
          <p style={{fontWeight: 600}}>Ocultar Big Numbers</p>
        </div>
      ) : (
        <div style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', zIndex: 99}}>
          <FaAngleDown size={20} onClick={() => setShowBigNumbers(true)} />
          <p style={{fontWeight: 600}}>Ver Big Numbers</p>
        </div>
      )}
    </HStack>
  )
}

export default Cards
