import React, { useState, useEffect } from 'react'
import {
  Flex,
  VStack,
  Checkbox,
  Divider,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import Modal from 'components/Modal'
import Button from 'components/Button'
import api from 'services/api'

interface IProps {
  sceneryId: number
  setShowResetSceneryButton: () => void
  showResetButtonScenery: boolean
  handleRefreshTable: () => void
}

const ResetSceneryButton: React.FC<IProps> = ({
  sceneryId,
  setShowResetSceneryButton,
  showResetButtonScenery,
  handleRefreshTable,
}) => {
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const handleOpenResetSceneryButton = (): void => {
    setOpenResetSceneryButton(true)
  }

  const [openResetSceneryButton, setOpenResetSceneryButton] =
    useState<boolean>(false)

  const onClose = (): void => {
    setOpenResetSceneryButton(false)
  }

  const handleResetScenery = async () => {
    try {
      setLoading(true)
      toast({
        title: 'Aguarde...',
        status: 'info',
      })
      await api
        .put('sceneries/reset-all-scenery-items', {
          sceneryId,
        })
        .then((res) => {
          // setLoading(false)
          toast({
            title: 'Dados resetados com sucesso!',
            status: 'success',
          })
          onClose()
          setShowResetSceneryButton()
          handleRefreshTable()
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
      toast({
        title: 'Ocorreu um erro, verifique com o administrador!',
        status: 'error',
      })
      console.log(error)
    }
  }

  return (
    <div>
      <Flex>
        <Button
          bg={'#870404'}
          style={{
            display: showResetButtonScenery === false ? 'none' : 'flex',
          }}
          padding="0.5rem 1rem"
          onClick={handleOpenResetSceneryButton}
          containerStyle={{
            backgroundColor: '#003b74',
          }}
        >
          Limpar Dados
        </Button>
      </Flex>
      <Modal
        w="800px"
        maxHeight="80vh"
        isOpen={openResetSceneryButton}
        title="Limpar Dados"
        onClose={onClose}
        body={
          <div>
            <span
              style={{ fontWeight: 'bold', color: '#870404', fontSize: 16 }}
            >
              Todos os dados serão limpos e o cenário voltará aos dados iniciais
            </span>
          </div>
        }
        footer={
          <VStack w="100%" align="flex-start">
            <Divider />
            <Flex w="100%" pt="0.5rem" justifyContent="space-between">
              <Flex
                flexDirection={'row'}
                w="30%"
                justifyContent="space-between"
              >
                <Button onClick={onClose}>Cancelar</Button>
                {/* <Button onClick={onReset}>Resetar</Button> */}
              </Flex>
              <Button onClick={handleResetScenery}>
                {!loading ? 'Limpar' : <Spinner />}
              </Button>
            </Flex>
          </VStack>
        }
      />
    </div>
  )
}

export default ResetSceneryButton
