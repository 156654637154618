import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import TableBackend from 'components/TableBackend'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'

import IRow from '../types/IRow'

import Input from './Input'
import LoadingScreen from 'components/LoadingScreen'
import { DataTable, SortOrder } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ModalUploadClassificacaoItem from './ModalUpload'
import ExportExcelButton from './ExportExcelButton'
import { Flex, useToast } from '@chakra-ui/react'
import IFilters from '../types/IFilters'
import Filters from '../Filters'
import api from 'services/api'
import Button from 'components/Button'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  loading: boolean
  options: IOption[]
  setOrderBy: Dispatch<SetStateAction<string>>
  orderBy: string
  sort: SortOrder
  setSort: Dispatch<SetStateAction<SortOrder>>
  setLimit: Dispatch<SetStateAction<number>>
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

interface ICellProps {
  row: IRow
}

const Table: React.FC<IProps> = ({
  rows,
  loading,
  options,
  page,
  limit,
  total,
  setPage,
  totalPages,
  setOrderBy,
  orderBy,
  sort,
  setSort,
  setLimit,
  filters,
  setFilters,
  setLoading,
}) => {
  const [data, setData] = useState<IRow[]>([])
  const [changedRows, setChangedRows] = useState<IRow[]>([])
  const toast = useToast()
  
  useEffect(() => {
    setData(rows)
  }, [rows])

  const selectTemplate = (rowData: any) => {
    return <Input row={rowData} options={options} onUpdate={updateRow} />
  }

  const updateRow = (updatedRow: IRow) => {
    const existingRowIndex = changedRows.findIndex(
      (item) =>
        +item.captain_code === +updatedRow.captain_code &&
        +item.cluster_id === +updatedRow.cluster_id
    )

    const newRow = { ...updatedRow }

    const updatedRows = data.map((row) =>
      +row.captain_code === +updatedRow.captain_code &&
      +row.cluster_id === +updatedRow.cluster_id
        ? newRow
        : row
    )

    setData(updatedRows)

    if (existingRowIndex !== -1) {
      const newChangedRows = [...changedRows]
      newChangedRows[existingRowIndex] = newRow
      setChangedRows(newChangedRows)
    } else {
      setChangedRows([...changedRows, newRow])
    }
  }

  const handleSave = async () => {
    try {
      const url = '/sensitivity'

      changedRows.forEach(async (row) => {
        const req = {
          cluster_id: row.cluster_id,
          captain_code: row.captain_code,
          sensitivity: row.sensitivity,
        }

        api.put(url, req)
      })

      setChangedRows([])
      toast({
        title: 'Atualização efetuada com sucesso',
        status: 'success',
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Erro, tente novamente.' + error,
        status: 'error',
        isClosable: true,
      })
      console.error('Erro ao salvar os dados:', error)
    }
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setData((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField].includes('.')
          ? Number(a[sortField])
          : a[sortField]
        const bValue = b[sortField].includes('.')
          ? Number(b[sortField])
          : b[sortField]

        if (aValue == null) return sort === 1 ? 1 : -1
        if (bValue == null) return sort === 1 ? -1 : 1

        if (aValue < bValue) return sort === 1 ? -1 : 1
        if (aValue > bValue) return sort === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrderBy(sortField)
    setSort(sort === 1 ? -1 : 1)
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  if (loading || options?.length === 0) return <LoadingScreen />
  return (
    <>
      <Flex marginBottom={5} justify="right" gap={5}>
        <Filters
          setPage={setPage}
          setLoading={setLoading}
          filters={filters}
          setFilters={setFilters}
        />
        <ExportExcelButton
          url={'/sensitivity'}
          type={`Tabela Classificação Item`}
          filter={filters}
        />
        <ModalUploadClassificacaoItem />
        <Button onClick={handleSave}>Salvar Alterações</Button>
      </Flex>
      <DataTable
        onSort={onSort}
        lazy
        sortField={orderBy}
        sortOrder={sort}
        onPage={onPage}
        scrollable
        scrollHeight="flex"
        paginator
        first={page * limit}
        rows={limit}
        totalRecords={total}
        rowsPerPageOptions={[5, 15, 25, 50]}
        value={data}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="cluster_name"
          header="Nome Cluster"
          style={{ minWidth: '100px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="captain_code"
          header="Código Capitão"
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="description"
          header="Descrição do Produto"
          style={{ minWidth: '200px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="level1_name"
          header="Nível 1"
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="level2_name"
          header="Nível 2"
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="level3_name"
          header="Nível 3"
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="level4_name"
          header="Nível 4"
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="level5_name"
          header="Nível 5"
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
          sortable
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="Estratégia do Item"
          body={selectTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
          frozen
        ></Column>
      </DataTable>
    </>
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
