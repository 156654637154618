import React, { useCallback, useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'

import { IProps } from './types'
import Button from 'components/Button'
import api from 'services/api'

const DeleteModal: React.FC<IProps> = ({
  isOpen,
  row,
  onClose,
  onDelete,
  onDeleteSelected,
  type,
}) => {
  const toast = useToast()
  const cancelRef = useRef<null>(null)

  const handleDelete = () => {
    if (type === 'one') onDelete()
    else {
      onDeleteSelected()
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent maxWidth="600px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deseja mesmo excluir esse concorrente?
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack alignItems="flex-start" fontSize="md" spacing={0.5}>
                {/* <Text marginBottom={'40px'} fontSize={'13pt'}>
                  {' '}
                  Cenário: {row.name}
                </Text> */}
                <Text color="#D20404">
                  Ao confirmar a exclusão, este item será removido de forma
                  imediata e irreversível.
                </Text>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Flex w="100%" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  containerStyle={{ backgroundColor: '#D20404' }}
                  onClick={handleDelete}
                >
                  Deletar
                </Button>
              </Flex>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default DeleteModal
