import { useCallback, useContext, useEffect, useState } from 'react'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import api from 'services/api'
import { UserContext } from 'state/user-context'

interface IProps {
  row: { [key: string]: any }
  updateCell?: (row: any) => void
  param: string
}

export const CheckboxTaxRecovery: React.FC<IProps> = ({
  row,
  updateCell = () => null,
  param,
}) => {
  const [check, setCheck] = useState(row[param])
  const { userParameters } = useContext(UserContext)

  const onChange = () => {
    setCheck(!check)
    const updatedRow = { ...row, [param]: !check }
    updateCell(updatedRow)
  }

  return (
    <ChakraCheckbox
      disabled={!userParameters.write}
      size="lg"
      isChecked={check}
      onChange={onChange}
    />
  )
}

export default CheckboxTaxRecovery
