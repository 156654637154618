import api from 'services/api'

interface IOptionsType {
  channels: IOption[]
  clusters: IOption[]
  clients: IOption[]
}

export const handleGetTradingConditionOptions = (
  ufs: string[],
  channelCodes: string[]
): Promise<IOptionsType> => {
  return api
    .post(`/trading-condition/filters`, { ufs, channelCodes })
    .then((res: any): IOptionsType => {
      return res.data
    })
}
