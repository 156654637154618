import IRow from '../../types/IRow'

interface IProps {
  row: IRow
}

const MediaPond: React.FC<IProps> = ({ row }) => {
  if (!row.average) return null
  const averageCount =
    (Number(String(row.regular_margin).replace(',', '.')) *
      Number(String(row.regular_share).replace(',', '.')) +
      Number(String(row.promo_margin).replace(',', '.')) *
        Number(String(row.promo_share).replace(',', '.'))) /
    100
  return <>{averageCount} %</>
}

export default MediaPond
