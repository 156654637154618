import React, { useRef } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  w?: string | number
  title?: string
  body?: React.ReactNode
  footer?: React.ReactNode
  maxHeight?: string | number
  children?: React.ReactNode
}

const ModalDraggable: React.FC<IProps> = ({
  isOpen,
  onClose,
  w = '40rem',
  maxHeight,
  title,
  body,
  footer,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null)

  const handleDragStart = (e: React.MouseEvent) => {
    const modal = modalRef.current
    const target = e.target as HTMLElement

    // Restrinja o arrasto apenas ao cabeçalho do modal
    if (!target.closest('.modal-header')) return

    if (!modal) return

    const offsetX = e.clientX - modal.getBoundingClientRect().left
    const offsetY = e.clientY - modal.getBoundingClientRect().top

    const handleMouseMove = (moveEvent: MouseEvent) => {
      modal.style.position = 'absolute'
      modal.style.top = `${moveEvent.clientY - offsetY}px`
      modal.style.left = `${moveEvent.clientX - offsetX}px`
    }

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        ref={modalRef}
        minW={w}
        maxH={maxHeight}
        position="absolute"
        top="30%"
        left="30%"
        transform="translate(-50%, -50%)"
        onMouseDown={handleDragStart}
      >
        <ModalHeader
          className="modal-header"
          cursor="grab"
          bg="gray.100"
          borderBottom="1px solid #e5e5e5"
          borderRadius="8px 8px 0px 0px"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        {body && <ModalBody>{body}</ModalBody>}
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  )
}

export default ModalDraggable
