const styles = (minWidth?: number) => ({
  headerStyle: { backgroundColor: '#d0e1fd', color: '#003b74' },
  minwidth: `${minWidth}px`,
  bodyStyle: { padding: '10', color: '#000' },
})

export const colsSimple = [
  { header: 'Nível 1', field: 'level1_name', style: styles() },
  { header: 'Nível 2', field: 'level2_name', style: styles() },
  { header: 'Nível 3', field: 'level3_name', style: styles() },
  { header: 'Nível 4', field: 'level4_name', style: styles() },
  { header: 'Nível 5', field: 'level5_name', style: styles() },
  {
    header: 'Preço Médio',
    field: 'price_average',
    style: styles(),
  },
  {
    header: 'Preço Tabela Atual',
    field: 'list_price_unit',
    style: styles(),
  },
  {
    header: 'Custo Médio Atual',
    field: 'average_unit_cost',
    style: styles(),
  },
  {
    header: 'Margem Atual',
    field: 'mrg_contribution_current',
    style: styles(),
  },
  {
    header: 'Desconto %',
    field: 'discount',
    style: styles(),
  },
  {
    header: 'Novo Preço',
    field: 'new_preco',
    style: styles(),
  },
  {
    header: 'Margem Nova',
    field: 'mrg_contribution_new',
    style: styles(),
  },
  {
    header: 'Qntd Negociada',
    field: 'qtd_negotiated',
    style: styles(),
  },
  // {
  //   header: 'Qntd Real',
  //   field: 'qtd_real',
  //   style: styles(),
  // },
  // {
  //   header: 'Venda Real',
  //   field: 'real_sale',
  //   style: styles(),
  // },
  // {
  //   header: 'Qntd Média',
  //   field: 'qtd_average',
  //   style: styles(),
  // },
  // {
  //   header: 'Venda Média',
  //   field: 'sale_average',
  //   style: styles(),
  // },
  // {
  //   header: 'Preço Médio',
  //   field: 'price_average',
  //   style: styles(),
  // },
  // {
  //   header: 'Variação',
  //   field: 'variation',
  //   style: styles(),
  // },
]

export const colsClient = [
  { field: 'cluster_name', header: 'Cluster', style: styles(100), frozen: true  },
  { field: 'channel_name', header: 'Canal', style: styles(100), frozen: true  },
  { field: 'client_name', header: 'Nome', style: styles(320), frozen: true  },  
  { field: 'captain_code', header: 'Código Capitão', style: styles(100), frozen: true  },
  { field: 'sku', header: 'SKU', style: styles(100), frozen: true  },
  { field: 'description', header: 'Descrição', style: styles(360), frozen: true },
  { header: 'Nível 1', field: 'level1_name', style: styles(120) },
  { header: 'Nível 2', field: 'level2_name', style: styles(120) },
  { header: 'Nível 3', field: 'level3_name', style: styles(120) },
  { header: 'Nível 4', field: 'level4_name', style: styles(120) },
  { header: 'Nível 5', field: 'level5_name', style: styles(120) },
  {
    header: 'Preço Médio',
    field: 'price_average',
    style: styles(120),
  },
  {
    header: 'Preço Tabela Atual',
    field: 'list_price_unit',
    style: styles(120),
  },
  {
    header: 'Custo Médio Atual',
    field: 'average_unit_cost',
    style: styles(120),
  },
  {
    header: 'Margem Atual',
    field: 'mrg_contribution_current',
    style: styles(120),
  },
  {
    header: 'Desconto %',
    field: 'discount',
    style: styles(140),
  },
  {
    header: 'Novo Preço',
    field: 'new_preco',
    style: styles(140),
  },
  {
    header: 'Margem Nova',
    field: 'mrg_contribution_new',
    style: styles(120),
  },
  {
    header: 'Qntd Negociada',
    field: 'qtd_negotiated',
    style: styles(120),
  },
  // {
  //   header: 'Qntd Real',
  //   field: 'qtd_real',
  //   style: styles(),
  // },
  // {
  //   header: 'Venda Real',
  //   field: 'real_sale',
  //   style: styles(),
  // },
  // {
  //   header: 'Qntd Média',
  //   field: 'qtd_average',
  //   style: styles(),
  // },
  // {
  //   header: 'Venda Média',
  //   field: 'sale_average',
  //   style: styles(),
  // },
  // {
  //   header: 'Preço Médio',
  //   field: 'price_average',
  //   style: styles(),
  // },
  // {
  //   header: 'Variação',
  //   field: 'variation',
  //   style: styles(),
  // },
  // {
  //   header: 'Qntd Negociada',
  //   field: 'qtd_negotiated',
  //   style: styles(),
  // },
  // {
  //   header: 'Faturamento Estimado',
  //   field: 'estimated_revenue',
  //   style: styles(),
  // },
]

export const oldColsSimple = [
  { header: 'Nível 1', field: 'level1_name', style: styles() },
  { header: 'Nível 2', field: 'level2_name', style: styles() },
  { header: 'Nível 3', field: 'level3_name', style: styles() },
  { header: 'Nível 4', field: 'level4_name', style: styles() },
  { header: 'Nível 5', field: 'level5_name', style: styles() },
  {
    header: 'Preço Tabela Atual',
    field: 'preco_liquido_unitario',
    style: styles(),
  },
  {
    header: 'Custo Média Atual',
    field: 'custo_medio_produto',
    style: styles(),
  },
  {
    header: 'Mrg Atual',
    field: 'margem_atual',
    style: styles(),
  },
  {
    header: 'Desconto',
    field: 'desconto',
    style: styles(),
  },
  {
    header: 'PPV Novo',
    field: 'ppv_novo',
    style: styles(),
  },
  { header: 'Histórico Quant.', field: 'volume_historico', style: styles() },
  {
    header: 'Quant. Estimada Venda',
    field: 'quantidade_estimada',
    style: styles(),
  },
  {
    header: 'Investimento',
    field: 'investimento',
    style: styles(),
  },
]
