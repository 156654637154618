import React, { memo, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import MainTable from './MainTable'
import { IRow, IFilters } from './types'
import { getItemsListService } from 'services/PriceBaseTable/get-items-list.service'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import api from 'services/api'
import './DataTable.css'
import { getSceneryService } from 'services/Scenery/get-scenery.service'

interface ITableProps {
  filters: IFilters
  typeView: string
  toggleBigData: () => void
  handleRefreshVerifyStatus: (status: boolean) => void
  handleSetIds: (ids: number[]) => void
  handleRefreshBigNumber: () => void
  newColumns: any
  setColumns: any
  setDinamicFilterData: (data: any) => void
}

const PriceBaseTable: React.FC<ITableProps> = ({
  filters,
  typeView,
  toggleBigData,
  handleRefreshVerifyStatus,
  handleSetIds,
  handleRefreshBigNumber,
  newColumns,
  setColumns,
  setDinamicFilterData,
}) => {
  const { id } = useParams<{ id: string }>()
  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState<number>(100)
  const [page, setPage] = useState<number>(0)
  const [selectedRows, setSelectedRows] = useState([])
  const [orderBy, setOrderBy] = useState('id')
  const [sort, setSort] = useState(1)
  const [total, setTotal] = useState(0)
  const [allChecked, setAllChecked] = useState({
    approve: false,
    reject: false,
  })
  const [statusScenery, setStatusScenery] = useState<boolean>(false)

  useEffect(() => {
    const getScenery = async () => {
      const res: any = await getSceneryService(Number(id) ?? 0)

      if (
        res.status === 'APPROVED' ||
        res.status === 'reject' ||
        res.status === 'Aprovado' ||
        res.status === 'Reprovado'
      )
        setStatusScenery(true)
    }
    getScenery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      selectedRows.length > 0 ||
      rows.filter((item: any) => item.status === 'S' || item.status === 'N')
    ) {
      handleRefreshVerifyStatus(true)
    } else {
      handleRefreshVerifyStatus(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, handleRefreshVerifyStatus])

  useEffect(() => {
    const initialSelected: any = rows.filter((row: any) => row.status === 'S')
    setSelectedRows(initialSelected)
  }, [rows])

  const handleNewChangeCheckBox = (rowData: any) => {
    onCheckboxChange(rowData)
    updateRow(rowData)
  }

  const onCheckboxChange = (rowData: any) => {
    const updatedRows = rows.map((row) => {
      if (
        Number(String(row.id).split('-')[0]) ===
        Number(String(rowData.id).split('-')[0])
      ) {
        return { ...row }
      }
      return row
    })

    setRows(updatedRows)

    const updatedSelection: any = updatedRows.filter(
      (row: any) => row.status === 'S'
    )
    setSelectedRows(updatedSelection)

    const req = {
      id: String(rowData.id).split('-')[0],
      status: rowData.status,
      type: typeView,
    }
    const url = '/sceneries/item'
    api.put(url, req).then(() => {})
  }

  const handleHeaderCheckboxChange = (type: string) => {
    const newAllChecked =
      type === 'approve'
        ? { approve: !allChecked.approve, reject: false }
        : { approve: false, reject: !allChecked.reject }

    setAllChecked(newAllChecked)

    const newSelection: any =
      newAllChecked.approve || newAllChecked.reject
        ? rows.map((row) => ({
            ...row,
            status: type === 'approve' ? 'S' : 'N',
          }))
        : []

    setSelectedRows(newSelection)

    const updatedRows = rows.map((row) => ({
      ...row,
      status: newAllChecked.approve ? 'S' : newAllChecked.reject ? 'N' : '',
    }))

    setRows(updatedRows)

    const url = '/sceneries/all-items'

    api.put(url, {
      ids: updatedRows.map((item: any) => +item.id),
      status: newAllChecked.approve ? 'S' : newAllChecked.reject ? 'N' : '',
      type: typeView,
    })
  }

  useEffect(() => {
    setLoading(true)
  }, [filters, id, typeView])

  const getRows = useCallback(async () => {
    const request: { [key: string]: string[] } = {}

    Object.keys(filters).forEach((key) => {
      request[key] = filters[key]?.map((v: IOption) => v.value) || []
    })
    try {
      const result = await getItemsListService(
        Number(id) ?? 0,
        { ...request, status: 'S', dinamicFilter: filters.dinamicFilter },
        page == 0 ? 1 : page + 1,
        limit,
        typeView,
        'request',
        'id',
        'asc'
        // orderBy,
        // sort === 1 ? 'asc' : 'desc'
      )

      const rowsMapped = result.data.payload.map((item: any) => {
        return {
          ...item,
          compareId: item.id,
        }
      })
      handleSetIds(rowsMapped.map((item: any) => item.id))
      setDinamicFilterData(result.dinamicFilter)
      setRows(rowsMapped)
      setTotal(result.data.total)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [filters, id, page, typeView, handleSetIds, limit, setDinamicFilterData])

  useEffect(() => {
    if (loading) getRows()
  }, [loading, getRows, filters])

  useEffect(() => {
    getRows()
  }, [page, limit, getRows])

  const updateRow = (newRow: any) => {
    handleRefreshBigNumber()
    setRows((prevRows: any) =>
      prevRows.map((row: any) => {
        const rowIdNumber = Number(String(row.id).split('-')[0])
        const newRowIdNumber = Number(String(newRow?.id)?.split('-')[0])

        if (rowIdNumber === newRowIdNumber) {
          return { ...row, status: newRow.status }
        }

        return row
      })
    )
  }

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <MainTable
        rows={rows}
        getRows={getRows}
        toggleBigData={toggleBigData}
        // inputsRef={inputsRef}
        selectedRows={selectedRows}
        onSelectionChange={handleNewChangeCheckBox}
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        setRows={setRows}
        allChecked={allChecked}
        statusScenery={statusScenery}
        typeView={typeView}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        newColumns={newColumns}
        setColumns={setColumns}
        setSort={setSort}
        setOrderBy={setOrderBy}
        sort={sort}
        total={total}
      />
    </Paper>
  )
}

export default memo(PriceBaseTable)
