import { useCallback, useContext, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'

import api from 'services/api'
import { UserContext } from 'state/user-context'

interface IProps {
  row: any
  clusterId: any
  updateCell: (row: any) => void
}

const InputRelativity: React.FC<IProps> = ({ clusterId, row, updateCell }) => {
  const { userParameters } = useContext(UserContext)
  const [relativity, setRelativity] = useState(row.relativity)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRelativity(e.target.value)
  }, [])

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const newVal = e.target.value || '0'
      setRelativity(newVal)
      const updatedRow = { ...row, relativity: newVal }
      updateCell(updatedRow)
    },
    [row, updateCell]
  )

  return (
    <InputGroup w="10rem">
      <InputLeftAddon>%</InputLeftAddon>
      <Input
        maxW="7rem"
        type="number"
        name="relativity"
        value={relativity}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={6}
        disabled={!userParameters.write}
      />
    </InputGroup>
  )
}

export default InputRelativity
