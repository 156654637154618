import { useCallback } from 'react'
import { HStack, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { IoPricetagsSharp as ParametersSvg } from 'react-icons/io5'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'

import IFilters from '../types/IFilters'
import FilterButton from './FilterButton'
import SendToApproveButton from './SendToApproveButton'
import { Container } from './styles'
import ConfigButton from './ConfigButton'
import RangeFilterButton from './RangeFilterButton'
import ExportExcelButton from './ExportExcelButton'
import ChangeBulkButton from './ChangeBulkButton'
import ResetSceneryButton from './ResetSceneryButton'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  typeView: string
  setTypeView: (type: string) => void
  refreshVerifyStatus: boolean
  columns: any
  newColumns: any
  setNewColumns: any
  dinamicFilterData: any
  idsToExport: number[]
  orderBy: string
  sort: string
  setUsingFilters: (boolean: boolean) => void
  usingFilters: boolean
  filterToUpdateBulk: any
  handleRefreshTable: () => void
  setShowResetSceneryButton: () => void
  showResetButtonScenery: boolean
}

const Header: React.FC<IProps> = ({
  filters,
  setFilters,
  typeView,
  setTypeView,
  refreshVerifyStatus,
  columns,
  newColumns,
  setNewColumns,
  dinamicFilterData,
  idsToExport,
  orderBy,
  sort,
  setUsingFilters,
  usingFilters,
  filterToUpdateBulk,
  handleRefreshTable,
  setShowResetSceneryButton,
  showResetButtonScenery,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const toggleSimpleView = useCallback(
    (type: string) => {
      setTypeView(type)
    },
    [setTypeView]
  )

  const onSave = useCallback(() => {
    toast({
      title: 'Salvo com sucesso!',
      status: 'success',
    })
    navigate('/pricebase')
  }, [navigate, toast])

  const translateTypeView = (type: string) => {
    if (type === 'simple') return 'Simples'
    if (type === 'detailed') return 'Detalhada'
    if (type === 'trading_condition') return 'Visão Cliente'
  }

  return (
    <Container>
      <HStack spacing="1rem">
        <PageTitle
          icon={<ParametersSvg />}
          title="Gestão de Preços"
          linkLabel="Cenário"
        />
      </HStack>
      <HStack spacing="1rem">
        <ConfigButton
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
        />
        <FilterButton
          filters={filters}
          setFilters={setFilters}
          setUsingFilters={setUsingFilters}
        />
        <ResetSceneryButton
          sceneryId={Number(id) ?? 0}
          showResetButtonScenery={showResetButtonScenery}
          setShowResetSceneryButton={setShowResetSceneryButton}
          handleRefreshTable={handleRefreshTable}
        />
        <ChangeBulkButton
          //  display={usingFilters ? 'flex' : 'none'}
          display="flex"
          filterToUpdateBulk={filterToUpdateBulk}
          handleRefreshTable={handleRefreshTable}
        />

        <RangeFilterButton
          filters={filters}
          setFilters={setFilters}
          dinamicFilterData={dinamicFilterData}
        />
        <Button
          style={{ display: 'flex' }}
          bg={typeView === 'simple' ? '#3f79b0' : '#003b74'}
          disabled={typeView === 'simple'}
          onClick={() => toggleSimpleView('simple')}
        >
          Simples
        </Button>
        <Button
          style={{ display: 'flex' }}
          bg={typeView === 'detailed' ? '#3f79b0' : '#003b74'}
          disabled={typeView === 'detailed'}
          onClick={() => toggleSimpleView('detailed')}
        >
          Detalhado
        </Button>
        <Button
          style={{ display: 'none' }}
          bg={typeView === 'trading_condition' ? '#3f79b0' : '#003b74'}
          disabled={typeView === 'trading_condition'}
          onClick={() => toggleSimpleView('trading_condition')}
        >
          Visão Cliente
        </Button>
        <Button onClick={onSave}>Salvar Cenário</Button>
        <ExportExcelButton
          url={`/sceneries/${id}/export-new`}
          type={`Tabela ${translateTypeView(typeView)}`}
          filter={filters}
          sceneryId={id ?? ''}
          idsToExport={idsToExport}
          sort={sort}
          orderBy={orderBy}
        />
        <SendToApproveButton refreshVerifyStatus={refreshVerifyStatus} />
      </HStack>
    </Container>
  )
}

export default Header
