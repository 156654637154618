import React, { useEffect, useMemo, useState } from 'react'

import TableBackend from 'components/TableBackend'

import IRow, { ITableProps } from '../types/IRow'
import Ean from './Ean'
import Indice from './Indice'
import Channel from './Channel'
import DateEnd from './DateEnd'
import DateIni from './DateIni'
import PriceType from './PriceType'
import Pricepoint from './Pricepoint'
import CalculationType from './CalculationType'
import Priceppc from './Priceppc'
import Options from './Options'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Paper from 'components/Paper'
import { Flex, useToast } from '@chakra-ui/react'
import Filters from '../Filters'
import ExportExcelButton from './ExportExcelButton'
import ModalUploadEstrategiaPosicionamento from './ModalUpload'
import api from 'services/api'
import Button from 'components/Button'

const Table: React.FC<ITableProps> = ({
  dataTable,
  page,
  limit,
  total,
  setPage,
  totalPages,
  getRows,
  loading,
  sort,
  setSort,
  setOrderBy,
  setLimit,
  filters,
  setIsFiltering,
  isFiltering,
  setFilters,
  setLoading,
}) => {
  const [order, setOrder] = useState(1)
  const [data, setData] = useState<IRow[]>([])
  const [changedRows, setChangedRows] = useState<IRow[]>([])
  const toast = useToast()

  useEffect(() => {
    if (dataTable) setData(dataTable)
  }, [dataTable])

  const pricepointTemplate = (rowData: IRow) => {
    return <Pricepoint row={rowData} getRows={getRows} updateCell={updateRow} />
  }

  const pricePpcTemplate = (rowData: any) => {
    return <Priceppc row={rowData} getRows={getRows} />
  }

  const researchTemplate = (rowData: any) => {
    return (
      <CalculationType row={rowData} getRows={getRows} updateCell={updateRow} />
    )
  }

  const indexTemplate = (rowData: any) => {
    return <Indice row={rowData} getRows={getRows} updateCell={updateRow} />
  }

  // const strategyTemplate = (rowData: any) => {
  //   return <Options row={rowData} />
  // }

  // const initialTemplate = (rowData: any) => {
  //   return <DateIni row={rowData} getRows={getRows} />
  // }

  // const finalTemplate = (rowData: any) => {
  //   return <DateEnd row={rowData} getRows={getRows} />
  // }

  const updateRow = (updatedRow: IRow) => {
    const existingRowIndex = changedRows.findIndex(
      (item) =>
        +item.cluster_id === +updatedRow.cluster_id &&
        +item.captain_code === +updatedRow.captain_code &&
        item.description === updatedRow.description
    )

    const newRow = { ...updatedRow }

    const updatedRows = data.map((row) =>
      +row.cluster_id === +updatedRow.cluster_id &&
      +row.captain_code === +updatedRow.captain_code &&
      row.description === updatedRow.description
        ? newRow
        : row
    )

    setData(updatedRows)

    if (existingRowIndex !== -1) {
      const newChangedRows = [...changedRows]
      newChangedRows[existingRowIndex] = newRow
      setChangedRows(newChangedRows)
    } else {
      setChangedRows([...changedRows, newRow])
    }
  }

  const handleSave = async () => {
    try {
      const url = '/politics'

      changedRows.forEach(async (row) => {
        await api.put(url, {
          cluster_id: row?.cluster_id,
          captain_code: row?.captain_code,
          new_price_ppc: row.new_price_ppc,
          is_pricepoint: row.is_pricepoint,
          calculation_type: row.calculation_type,
          index: Number(row.index.replace(',', '.')),
        })
      })

      setChangedRows([])
      toast({
        title: 'Atualização efetuada com sucesso',
        status: 'success',
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Erro, tente novamente.' + error,
        status: 'error',
        isClosable: true,
      })
      console.error('Erro ao salvar os dados:', error)
    }
  }

  const onSort = (event: any) => {
    const { sortField } = event
    setOrderBy(sortField)

    setSort(sort === 1 ? -1 : 1)
  }

  const onPage = (event: any) => {
    setPage(event.page)
    setLimit(event.rows)
  }

  return (
    <Paper containerStyle={{ width: '100%' }}>
      <Flex marginBottom={5} justify="right">
        <div style={{ gap: 20, display: 'flex', flexDirection: 'row' }}>
          <Filters
            setPage={setPage}
            setLoading={setLoading}
            filters={filters}
            setIsFiltering={setIsFiltering}
            isFiltering={isFiltering}
            setFilters={setFilters}
          />
          <ExportExcelButton
            type={`Tabela Estrategia de Posicionamento`}
            filter={filters}
          />
          <ModalUploadEstrategiaPosicionamento />
          <Button onClick={handleSave}>Salvar Alterações</Button>
        </div>
      </Flex>

      <DataTable
        scrollable
        scrollHeight="flex"
        paginator
        lazy
        rows={limit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={data}
        loading={loading}
        onSort={onSort}
        totalRecords={total}
        first={page * limit}
        onPage={onPage}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="cluster_name"
          header="Cluster de Preço"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="captain_code"
          header="SKU Capitão"
          style={{ minWidth: '120px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="description"
          header="Descrição"
          style={{ minWidth: '200px', zIndex: '2' }}
        ></Column>
        {/* <Column
        bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="sensitivity"
        header="Classificação do Item"
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column> */}
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field=""
          header="PricePoint"
          body={pricepointTemplate}
          style={{ minWidth: '30px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="new_price_ppc"
          header="Preço PPC"
          body={pricePpcTemplate}
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="description"
          body={researchTemplate}
          header="Base de Pesquisa Cálculo"
          style={{ minWidth: '100px', zIndex: '2' }}
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          field="index"
          header="Índice"
          body={indexTemplate}
          style={{ minWidth: '50px', zIndex: '2' }}
        ></Column>
        {/* <Column
        bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="start_date"
        header="Data Início Período"
        body={initialTemplate}
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column>
      <Column
      bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
        headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
        field="end_date"
        header="Data Fim Período"
        body={finalTemplate}
        style={{ minWidth: '50px', zIndex: '2' }}
      ></Column> */}
      </DataTable>
    </Paper>
  )
}

export default Table
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠿⠿⠿⠿⠿⠿⣿⣿⣿⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡿⠋⣉⣁⣤⣤⣶⣾⣿⣿⣶⡄⢲⣯⢍⠁⠄⢀⢹⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⢯⣾⣿⣿⣏⣉⣹⠿⠇⠄⠽⠿⢷⡈⠿⠇⣀⣻⣿⡿⣻
// ⣿⣿⡿⠿⠛⠛⠛⢛⡃⢉⢣⡤⠤⢄⡶⠂⠄⠐⣀⠄⠄⠄⠄⠄⡦⣿⡿⠛⡇⣼
// ⡿⢫⣤⣦⠄⠂⠄⠄⠄⠄⠄⠄⠄⠄⠠⠺⠿⠙⠋⠄⠄⠄⠢⢄⠄⢿⠇⠂⠧⣿
// ⠁⠄⠈⠁⠄⢀⣀⣀⣀⣀⣠⣤⡤⠴⠖⠒⠄⠄⠄⠄⠄⠄⠄⠄⠄⠘⢠⡞⠄⣸
// ⡀⠄⠄⠄⠄⠄⠤⠭⠦⠤⠤⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⣂⣿
// ⣷⡀⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢳⠄⠄⢀⠈⣠⣤⣤⣼⣿
// ⣿⣿⣷⣤⣤⣤⣤⣤⣤⣤⣤⣤⣤⣴⣶⣶⣶⣄⡀⠄⠈⠑⢙⣡⣴⣿⣿⣿⣿⣿
// ⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿
