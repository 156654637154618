import React, { useEffect, useMemo, useState } from 'react'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import IPaginationOptions from 'components/TableBackend/types/IPaginationOptions'
import IRow from '../types/IRow'
import Input from './Input'
import api from '../../../../../services/api'
import { Flex, useToast } from '@chakra-ui/react'
import Button from 'components/Button'
import IFilters from '../types/IFilters'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import ExportExcelButton from './ExportExcelButton'
import ModalUploadMargemIndustria from './ModalUpload'

interface IProps extends IPaginationOptions {
  rows: IRow[]
  filters: IFilters
  loading: boolean
  clusterId: string
  binaryPos: number[]
}

interface ICellProps {
  row: IRow
  clusterId: string
  updateRow: (id: string, updatedRow: IRow, param: string) => void
}

const Table: React.FC<IProps> = ({ rows, loading, clusterId, filters }) => {
  const [changedRows, setChangedRows] = useState<IRow[]>([])
  const [rowsState, setRowsState] = useState<any[]>([])
  const toast = useToast()
  const [order, setOrder] = useState(1)

  useEffect(() => {
    setRowsState(rows)
  }, [rows])

  const updateRow = (id: string, updatedRow: IRow, param: string) => {
    const existingRowIndex = changedRows.findIndex((item) => item.id === id)

    let newRow = { ...updatedRow }

    if (existingRowIndex !== -1) {
      newRow = {
        ...changedRows[existingRowIndex],
        [param]: updatedRow[param],
      }
    }

    let regularValue = +newRow[param]
    let promoValue =
      param === 'regular_share' ? 100 - regularValue : +newRow['promo_share']

    if (regularValue < 0) {
      regularValue = 0
      promoValue = 100
    } else if (regularValue > 100) {
      regularValue = 100
      promoValue = 0
    } else if (promoValue < 0) {
      promoValue = 0
      regularValue = 100
    } else if (promoValue > 100) {
      promoValue = 100
      regularValue = 0
    }

    newRow[param] = regularValue
    newRow['promo_share'] = promoValue

    if (param == 'range_min_max' || param == 'objective_margin') {
      newRow['range_min'] =
        Number(newRow['objective_margin']) - Number(newRow['range_min_max'])
      newRow['range_max'] =
        Number(newRow['objective_margin']) + Number(newRow['range_min_max'])
    }

    const updatedRows = rowsState.map((row) => (row.id === id ? newRow : row))

    setRowsState(updatedRows)

    if (existingRowIndex !== -1) {
      const newChangedRows = [...changedRows]
      newChangedRows[existingRowIndex] = newRow
      setChangedRows(newChangedRows)
    } else {
      setChangedRows([...changedRows, newRow])
    }
  }

  const handleSave = async () => {
    try {
      const url = '/industry-margins'

      const newList: IRow[] = []

      changedRows.forEach((row) => {
        newList.push({
          id: row?.id,
          level1_code: row?.level1_code,
          level2_code: row?.level2_code,
          level3_code: row?.level3_code,
          level4_code: row?.level4_code,
          level5_code: row?.level5_code,
          cluster_id: Number(row?.cluster_id),
          channel_code: row?.channel_code,
          objective_margin: Number(row?.objective_margin),
          range_min_max: Number(row?.range_min_max),
          range_min: Number(row?.range_min),
          range_max: Number(row?.range_max),
        })
      })

      const response = await api.put(url, newList)

      const responseMapped = rowsState.map((item) => {
        const sameItem = response.data.data.filter(
          (value: any) => value.id === item.id
        )

        if (sameItem) {
          return {
            ...item,
            range_max: sameItem.range_max ?? item.range_max,
            objective_margin:
              sameItem.objective_margin ?? item.objective_margin,
            range_min: sameItem.range_min ?? item.range_min,
            range_min_max: sameItem.range_min_max ?? item.range_min_max,
          }
        }
        return item
      })

      setRowsState(responseMapped)

      setRowsState((prevRows) => {
        const updatedRowsMap = new Map(
          response.data.data.map((row: any) => [row.id, row])
        )

        return prevRows.map((row) =>
          updatedRowsMap.has(row.id) ? updatedRowsMap.get(row.id) : row
        )
      })

      setChangedRows([])
      toast({
        title: 'Atualização efetuada com sucesso',
        status: 'success',
        isClosable: true,
      })
    } catch (error) {
      toast({
        title: 'Erro, tente novamente.' + error,
        status: 'error',
        isClosable: true,
      })
      console.error('Erro ao salvar os dados:', error)
      // Trate o erro de acordo com a lógica da sua aplicação
    }
  }

  const onSort = (event: any) => {
    const { sortField } = event

    setRowsState((prevData) => {
      const sortedData = [...prevData].sort((a: any, b: any) => {
        const aValue = a[sortField]
        const bValue = b[sortField]

        if (aValue == null) return order === 1 ? 1 : -1
        if (bValue == null) return order === 1 ? -1 : 1

        if (aValue < bValue) return order === 1 ? -1 : 1
        if (aValue > bValue) return order === 1 ? 1 : -1

        return 0
      })

      return sortedData
    })

    setOrder(order === 1 ? -1 : 1)
  }

  const rangeTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="range_min_max"
        clusterId={clusterId}
        onUpdate={updateRow} 
      />
    )
  }

  const minTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="range_min"
        clusterId={clusterId}
        onUpdate={updateRow} 
      />
    )
  }

  const maxTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="range_max"
        clusterId={clusterId}
        onUpdate={updateRow} 
      />
    )
  }

  const margemTemplate = (rowData: any) => {
    return (
      <Input
        row={rowData}
        param="objective_margin"
        clusterId={clusterId}
        onUpdate={updateRow} 
        isPercent
      />
    )
  }

  if (!clusterId) return null
  if (loading) return <LoadingScreen />
  return (
    <Paper containerStyle={{ width: '100%' }}>
      <Flex marginBottom={5} justify="right" gap={5}>
        <ExportExcelButton
          url={`/margin-industry`}
          type={`Tabela Margem Industria`}
          filter={filters}
        />
        <ModalUploadMargemIndustria />
        <Button onClick={handleSave}>Salvar Alterações</Button>
      </Flex>
      <DataTable
        onSort={(e) => onSort(e)}
        scrollable
        scrollHeight="flex"
        paginator
        rows={15}
        rowsPerPageOptions={[5, 10, 25, 50]}
        value={rowsState}
        // selectionMode="multiple"
        // selection={selectedRows}
        // onSelectionChange={onSelectionChange}
        dataKey="id"
        key={rowsState.length} 
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        emptyMessage="Sem dados"
        filterDisplay="menu"
        currentPageReportTemplate="Exibindo de {first} à {last} de {totalRecords} registros"
      >
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="cluster_name"
          header="Cluster"
          style={{ minWidth: '100px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>

        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="channel_name"
          header="Canal"
          style={{ minWidth: '100px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level1_name"
          header="Nível 1"
          style={{ minWidth: '120px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level2_name"
          header="Nível 2"
          style={{ minWidth: '80px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level3_name"
          header="Nível 3"
          style={{ minWidth: '100px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level4_name"
          header="Nível 4"
          style={{ minWidth: '100px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="level5_name"
          header="Nível 5"
          style={{ minWidth: '100px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Margem Objetiva"
          body={margemTemplate}
          style={{ minWidth: '50px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field=""
          header="Range P.P"
          body={rangeTemplate}
          style={{ minWidth: '50px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="range_min"
          body={minTemplate}
          header="Margem Mín"
          style={{ minWidth: '50px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
        <Column
          bodyStyle={{
            borderBottom: 'solid 1px #e5e5e5',
            color: '#000',
          }}
          headerStyle={{ backgroundColor: '#d0e1fd', color: '#003b74' }}
          sortable
          field="range_max"
          body={maxTemplate}
          header="Margem Máx"
          style={{ minWidth: '50px', zIndex: '2', color: '#000' }}
          frozen
        ></Column>
      </DataTable>
      {/* <TableBackend
        rows={rowsState}
        cols={cols}
        page={page}
        limit={limit}
        total={total}
        setPage={setPage}
        totalPages={totalPages}
        cellProps={{ clusterId, updateRow }}
      /> */}
    </Paper>
  )
}

export default Table
