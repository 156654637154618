import styled from 'styled-components'

interface ButtonProps {
  width?: string
  disable?: boolean
  padding: string
  bg?: string
}

export const Container = styled.button<ButtonProps>`
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'boolean')};
  position: relative;
  width: ${({ width }) => (!!width ? width : 'auto')};
  height: 2.8rem;
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 4px;
  white-space: nowrap;
  background-color: ${({ bg }) => (bg || "#003b74")};
  transition: all 200ms ease-in-out;
  filter: ${({ disable }) => disable && 'opacity(50%)'};
  &:hover {
    filter: ${({ disable }) => !disable && 'brightness(110%)'};
    color: #fff;
  }
`
