import { useCallback, useState } from 'react'

import Cards from './Cards'
import Table from './Table2'
import Header from './Header'
import IFilters from './types/IFilters'
import { BodyContent, Container, HeadContent } from './styles'
import { colsSimple } from './Table2/dynamicColumns'

const ApprovalPriceBaseTableV2: React.FC = () => {
  const [filters, setFilters] = useState({} as IFilters)
  const [typeView, setTypeView] = useState<string>('simple')
  const [refreshVerifyStatus, setRefreshVerifyStatus] = useState(false)
  const [ids, setIds] = useState<number[]>([])
  const [columns, setColumns] = useState(colsSimple)
  const [newColumns, setNewColumns] = useState(colsSimple)
  const [dinamicFilterData, setDinamicFilterData] = useState()
  const [refreshBigNumbers, setRefreshBigNumbers] = useState<boolean>(false)

  const toggleBigData = useCallback(() => {
    // setFilters(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <HeadContent>
        <Header
          filters={filters}
          setFilters={setFilters}
          typeView={typeView}
          setTypeView={setTypeView}
          columns={columns}
          newColumns={newColumns}
          setNewColumns={setNewColumns}
          dinamicFilterData={dinamicFilterData}
        />
        <Cards
          ids={ids}
          typeView={typeView}
          refreshBigNumbers={refreshBigNumbers}
          filters={filters}
        />
      </HeadContent>

      <BodyContent>
        <Table
          newColumns={newColumns}
          setColumns={setColumns}
          handleRefreshBigNumber={() =>
            setRefreshBigNumbers(!refreshBigNumbers)
          }
          handleSetIds={setIds}
          filters={filters}
          typeView={typeView}
          toggleBigData={toggleBigData}
          handleRefreshVerifyStatus={(e) => setRefreshVerifyStatus(e)}
          setDinamicFilterData={setDinamicFilterData}
        />
      </BodyContent>
    </Container>
  )
}

export default ApprovalPriceBaseTableV2
