import React, { useCallback, useContext, useState } from 'react'
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Select,
  Text,
  Checkbox,
  HStack,
} from '@chakra-ui/react'

import api from '../../../../../../services/api'

import { IProps } from '../../types/IRow'
import Button from '../../../../../../components/Button'
import { UserContext } from 'state/user-context'

const options = [
  { label: 'Selecione...', value: '' },
  { label: 'MÍNIMO', value: 'Mínimo' },
  { label: 'MÉDIA', value: 'Média' },
  { label: 'MÁXIMO', value: 'Máximo' },
  { label: 'MODA', value: 'Moda' },
  { label: 'PRIORIDADE', value: 'Prioridade' },
]

const CalculationType: React.FC<IProps> = ({
  row,
  getRows = () => null,
  updateCell = () => null,
}) => {
  const { userParameters } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [value, setValue] = useState<string>(() => {
    const initialOption = options.find(
      (opt) =>
        opt.label === row?.calculation_type ||
        opt.value === row?.calculation_type
    )
    return initialOption ? initialOption.value : ''
  })
  const [selectedValue, setSelectedValue] = useState<string>()
  const [repeatedValues, setRepeatedValues] = useState<string[]>([])
  const toast = useToast()

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newVal = e.target.value
      setValue(newVal)

      const updatedRow = { ...row, calculation_type: newVal }
      updateCell(updatedRow)

      // const url = '/politics/'
      // const req = {
      //   cluster_id: row?.cluster_id,
      //   calculation_type: newVal,
      //   id: row?.id,
      //   captain_code: row?.captain_code,
      //   sensitivity: row?.sensitivity,
      // }

      // api
      //   .put(url, req)
      //   .then((res) => {
      //     const repeatedValues = res.data?.data?.repeatedPrices
      //     if (repeatedValues?.length > 1) {
      //       setRepeatedValues(repeatedValues)
      //       setSelectedValue('')
      //       onOpen()
      //     }
      //     toast({
      //       status: 'success',
      //       title: 'Alteração efetuada com sucesso',
      //     })
      //     getRows()
      //     setTimeout(() => {
      //       setValue('')
      //       setSelectedValue('')
      //     }, 2000)
      //   })
      //   .catch((error) => {
      //     toast({
      //       status: 'error',
      //       title: `Erro, tente novamente: ${error?.message}`,
      //     })
      //     setValue(row?.calculation_type)
      //   })
    },
    [row, updateCell]
  )

  const onSave = useCallback(() => {
    const request = {
      medianValue: selectedValue,
    }
    api
      .post('/rota', request)
      .then(() => {
        toast({
          title: 'Salvo com sucesso!',
          status: 'success',
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: `Erro, tente novamente: ${error?.message}`,
          status: 'error',
          isClosable: true,
        })
      })
  }, [toast, selectedValue])

  const verifyType = (type: string) => {
    const option = options.find(
      (opt) => opt.label === type || opt.value === type
    )
    if (option) {
      setValue(option.value)
    } else {
      console.warn(`Type "${type}" not found in options`)
      setValue('')
    }
  }

  return (
    <>
      <Select
        w="8rem"
        value={value}
        onChange={onChange}
        disabled={
          !userParameters.write || (userParameters.write && row?.is_pricepoint)
        }
      >
        {options?.map((option) => (
          <option key={option?.label} value={option?.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="50rem">
          <ModalHeader color={'red'}>
            {"Múltiplos valores para 'Moda' existentes"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={'12pt'}>
              Escolha um valor para o cálculo da Moda:
            </Text>
            {repeatedValues?.map((item) => (
              <HStack key={item}>
                <Checkbox
                  size="lg"
                  isChecked={selectedValue === item}
                  onChange={() => {
                    setSelectedValue(item)
                  }}
                >
                  <Text fontSize="12pt">{item}</Text>
                </Checkbox>
              </HStack>
            ))}
          </ModalBody>
          <ModalFooter>
            <Flex w="100%" justifyContent="space-between">
              <Button onClick={onClose}>Cancelar</Button>
              <Button onClick={onSave}>Adicionar</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CalculationType
