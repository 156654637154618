import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'

import api from '../../../../services/api'
import Paper from '../../../../components/Paper'
import LoadingScreen from '../../../../components/LoadingScreen'

import Table from './Table'
import IRow from './types/IRow'
import { SortOrder } from 'primereact/datatable'

const UploadDePrecos = () => {
  const toast = useToast()
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [totalPages, setTotalPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(15)
  const [sort, setSort] = useState<SortOrder>(1)
  const [orderBy, setOrderBy] = useState<string>('id')
  const [data, setData] = useState<IRow[]>([])

  const getRows = () => {
    api
      .get(
        `/market-prices/upload?page=${page === 0 ? 1 : page + 1}&limit=${limit}`
      )
      .then((response) => {
        setData(response.data?.data?.data?.payload)
        setTotal(response.data?.data?.data?.total)
        setTotalPage(response.data?.data?.data?.totalPages)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }

  useEffect(() => {
    if (data.length < 1) {
      getRows()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <Table
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        sort={sort}
        setSort={setSort}
        setLimit={setLimit}
        rows={data}
        page={page}
        limit={limit}
        total={total}
        setPage={(newPage) => {
          setLoading(true)
          setPage(newPage)
        }}
        totalPages={totalPages}
      />
    </Paper>
  )
}

export default UploadDePrecos
