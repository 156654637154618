import { useCallback } from 'react'
import { HStack, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FaCheckDouble as ApprovalSvg } from 'react-icons/fa'

import Button from 'components/Button'
import PageTitle from 'components/PageTitle'
import ExportExcelButton from 'components/ExportExcelButton'

import IFilters from '../types/IFilters'
import FilterButton from './FilterButton'
import ConfirmButton from './ConfirmButton'
import { Container } from './styles'
import ConfigButton from './ConfigButton'
import RangeFilterButton from './RangeFilterButton'

interface IProps {
  filters: IFilters
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>
  typeView: string
  setTypeView: (type: string) => void
  columns: any
  newColumns: any
  setNewColumns: React.Dispatch<any>,
  dinamicFilterData: any
}

const Header: React.FC<IProps> = ({
  filters,
  setFilters,
  typeView,
  setTypeView,
  columns,
  newColumns,
  setNewColumns,
  dinamicFilterData,
}) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id, type } = useParams()

  const onSave = useCallback(() => {
    if (type === 'edit') {
      toast({
        title: 'Salvo com sucesso!',
        status: 'success',
      })
    }

    navigate('/approval')
  }, [type, toast, navigate])

  const translateTypeView = (type: string) => {
    if (type === 'simple') return 'Simples'
    if (type === 'detailed') return 'Detalhada'
    if (type === 'trading_condition') return 'Visão Cliente'
  }

  return (
    <Container>
      <HStack spacing="1rem">
        <PageTitle icon={<ApprovalSvg />} title="Requisições" />
      </HStack>
      <HStack spacing="1rem">
      <ConfigButton columns={columns} setNewColumns={setNewColumns} newColumns={newColumns}/>
        <FilterButton filters={filters} setFilters={setFilters} />
        <RangeFilterButton
          filters={filters}
          setFilters={setFilters}
          dinamicFilterData={dinamicFilterData}
        />
        <Button
          style={{ display: 'flex' }}
          bg={typeView === 'simple' ? '#3f79b0' : '#003b74'}
          disabled={typeView === 'simple'}
          onClick={() => setTypeView('simple')}
        >
          Simples
        </Button>
        <Button
          style={{ display: 'flex' }}
          bg={typeView === 'detailed' ? '#3f79b0' : '#003b74'}
          disabled={typeView === 'detailed'}
          onClick={() => setTypeView('detailed')}
        >
          Detalhado
        </Button>
        <Button
          style={{ display: 'none' }}
          bg={typeView === 'trading_condition' ? '#3f79b0' : '#003b74'}
          disabled={typeView === 'trading_condition'}
          onClick={() => setTypeView('trading_condition')}
        >
          Visão Cliente
        </Button>
        {type !== 'edit' && (
          <ExportExcelButton
            url={`/requests/${id}/export`}
            type={`Tabela ${translateTypeView(typeView)}`}
            filter={filters}
            sceneryId={id ?? ''}
          />
        )}
        <Button onClick={onSave}>
          {type === 'edit' ? 'Salvar' : 'Voltar'}
        </Button>
        {type === 'edit' && <ConfirmButton />}
      </HStack>
    </Container>
  )
}

export default Header
