import { useCallback, useContext, useEffect, useState } from 'react'
import { Select, Text, useToast } from '@chakra-ui/react'
import Table from './Table'
import IRow from './types/IRow'
import api from 'services/api'
import Paper from 'components/Paper'
import LoadingScreen from 'components/LoadingScreen'
import { UserContext } from 'state/user-context'
import { FaBan } from 'react-icons/fa'

const limit = 20

const PlanoDeMetas = () => {
  const { userGoalsPlan } = useContext(UserContext)
  const toast = useToast()
  const [rows, setRows] = useState<IRow[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [year, setYear] = useState<number>(2025)
  const yearOptions = [
    { label: '2024', value: 2024 },
    { label: '2025', value: 2025 },
    { label: '2026', value: 2026 },
  ]

  const getRows = useCallback(() => {
    api
      .post(`/goals-plan/list?year=${year}`)
      .then((response) => {
        setRows(response.data?.data)
        setLoading(false)
      })
      .catch(() => {
        if (!toast.isActive('data')) {
          toast({
            id: 'data',
            title: 'Erro ao carregar dados, tente novamente.',
            status: 'error',
            isClosable: true,
          })
        }
      })
  }, [toast, year])

  useEffect(getRows, [getRows])

  if (loading) return <LoadingScreen />
  return (
    <Paper>
      <div
        style={{
          display: userGoalsPlan.read ? 'flex' : 'none',
          flexDirection: 'row',
          width: 400,
        }}
      >
        <div>
          <Text
            style={{
              marginRight: 15,
              fontSize: 14,
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
            }}
          >
            Definição de metas do ano:{' '}
          </Text>
          <Text
            style={{
              marginRight: 15,
              fontSize: 14,
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              marginTop: 15,
              marginBottom: 20,
            }}
          >
            Informações
          </Text>
        </div>

        <Select value={year} onChange={(e) => setYear(Number(e.target.value))}>
          {yearOptions.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </div>
      {userGoalsPlan.read ? (
        <Table
          rows={rows}
          year={year}
          setYear={setYear}
          onRefresh={() => getRows()}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FaBan color='gray' size={80} />
          <span style={{ marginTop: 15, fontSize: 18, fontWeight: 600 }}>
            Você não tem autorização para ver o Plano de Metas
          </span>
        </div>
      )}
    </Paper>
  )
}

export default PlanoDeMetas
