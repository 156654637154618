import React, { useState, useMemo, useCallback } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { StyledAutocomplete } from './styles';

interface IProps {
  label?: string;
  value: any;
  options: IOption[];
  setValue: any;
  isMulti?: boolean;
  loading?: boolean;
  disabled?: boolean;
  pageSize?: number; 
}

const AutocompleteCliente: React.FC<IProps> = ({
  label,
  value,
  options,
  setValue,
  isMulti = false,
  loading = false,
  disabled = false,
  pageSize = 20, 
}) => {
  const [inputValue, setInputValue] = useState('');
  const [visibleOptions, setVisibleOptions] = useState<IOption[]>(options.slice(0, pageSize)); 
  const [currentPage, setCurrentPage] = useState(1);

  const filteredOptions = useMemo(() => {
    if (!inputValue) return visibleOptions;
    return options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, [inputValue, visibleOptions, options]);

  const loadMoreOptions = useCallback(() => {
    const nextPage = currentPage + 1;
    const newOptions = options.slice(0, nextPage * pageSize);
    setVisibleOptions(newOptions);
    setCurrentPage(nextPage);
  }, [currentPage, options, pageSize]);

  return (
    <VStack w="100%" align="flex-start">
      {label && <Text>{label}</Text>}
      <StyledAutocomplete
        isDisabled={disabled}
        isMulti={isMulti}
        options={filteredOptions} 
        value={value}
        onChange={(val) => setValue(val)}
        onInputChange={(val) => setInputValue(val)} 
        isSearchable
        isClearable={false}
        isLoading={loading}
        placeholder={`${loading ? '. Carregando' : '. Selecione'}...`}
        onMenuScrollToBottom={loadMoreOptions} 
      />
    </VStack>
  );
};

export default AutocompleteCliente;
