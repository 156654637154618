import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'

import api from 'services/api'

interface IProps {
  row: any
  checked: any
  clusterId: any
  setChecked: any
  findCaptain: any
  updateCell: (row: any) => void
}

const Checkbox: React.FC<IProps> = ({
  row,
  checked,
  clusterId,
  setChecked,
  findCaptain,
  updateCell,
}) => {
  const toast = useToast()

  const onCheck = useCallback(() => {
    setChecked(row.id)
    const updatedRow = { ...row, is_captain: checked === row.id }
    // updateCell(updatedRow)
    api
      .put(`/relativity-channels/${clusterId}/captain`, { channel_id: row.id })
      .catch(() => {
        findCaptain()
        toast({
          title: 'Erro, tente novamente.',
          status: 'error',
          isClosable: true,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, checked, setChecked, clusterId, findCaptain])

  return (
    <input
      width={'20px'}
      type="radio"
      checked={checked === row.id}
      onChange={onCheck}
    />
  )
}

export default Checkbox
