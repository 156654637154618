import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'
import { useToast } from '@chakra-ui/react'
import api from 'services/api'
import { UserContext } from 'state/user-context'
import { InputText } from 'primereact/inputtext'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import './styles.css'

interface IProps {
  type: string
  updateValue: (value: string) => void
}

export const InputToFilter: React.FC<IProps> = ({ type, updateValue }) => {
  const toast = useToast()
  const { userScenery } = useContext(UserContext)
  const formatValue = (value: any) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 1,
    })?.format(parseFloat(String(value)?.replace(',', '.')))

  const [value, setValue] = useState('0,0')
  const [focusValue, setFocusValue] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onFocus = useCallback(() => {
    setFocusValue(value)
    setValue('')
  }, [value])

  const borderColor = '#BEE3F8'

  const onBlur = () => {
    updateValue(value)
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value.replace(',', '.'))

    setValue(e.target.value)
    updateValue(e.target.value)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className="p-inputgroup">
        <span
          style={{ display: type?.includes('money') ? 'flex' : 'none' }}
          className="p-inputgroup-addon"
        >
          R$
        </span>
        <InputText
          ref={inputRef}
          disabled={!userScenery.write}
          style={{
            height: 32,
            borderColor: borderColor,
            textAlign: 'center',
            maxWidth: 80,
          }}
          value={value}
          onBlur={onBlur}
          placeholder={focusValue}
          onChange={onChange}
          //   onKeyDown={handleKeyDown}
          onFocus={onFocus}
        />
        <span
          style={{ display: type?.includes('percent') ? 'flex' : 'none' }}
          className="p-inputgroup-addon"
        >
          %
        </span>
      </div>
    </div>
  )
}

export default InputToFilter
