import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import Button from '../../../../../components/Button'
import Autocomplete from '../../../../../components/Autocomplete'
import api from 'services/api'
import { IChannels } from '../../ClassificacaoDoItem/types/IRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UserContext } from 'state/user-context'
import { UploadButton } from 'pages/ParametersEdit/Header/PrecosDeMercadoUpload/styles'
import LoadingModal from 'components/LoadingModal'

interface IProps {
  clients: any
  channels: any
  setNewRows: (data: any[]) => void
}

const ModalUploadCompetitor: React.FC<IProps> = ({
  clients,
  channels,
  setNewRows,
}) => {
  const loadingRef = useRef<any>(null)
  const { user } = useContext(UserContext)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [channelsOpt, setChannelsOpt] = useState([])
  const [clientsOpt, setClientsOpt] = useState([])
  const [channelsSelected, setChannelsSelected] = useState([])
  const [clientsSelected, setClientsSelected] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [clusterIdOpt, setClusterIdOpt] = useState([])
  const [clusterSelected, setClusterSelected] = useState([])

  useEffect(() => {
    setChannelsSelected([])
    setClientsSelected([])
  }, [isOpen])

  const handleAddClient = (ids: any) => {
    setIsLoading(true)
    toast({
      title: `Aguarde enquanto estamos atualizando os dados`,
      status: 'info',
      isClosable: true,
    })
    api
      .put('/trading-condition/update-clients', { ids })
      .then((res: any) => {
        onClose()
        setNewRows(res.data)
        setIsLoading(false)

        if (res.status === 201) {
          toast({
            title: `Os Clientes foram atualizados com sucesso`,
            status: 'success',
            isClosable: true,
          })
        }
      })
      .catch(() => {
        toast({
          title: `Houve um erro ao atualizar os clientes`,
          status: 'error',
          isClosable: true,
        })
        setIsLoading(false)
      })
  }

  const getClusterId = useCallback(() => {
    isOpen &&
      api
        .get('/clusters/all')
        .then((res) => {
          if (user?.clusters?.includes(0)) {
            setClusterIdOpt(
              res.data.data.clusters.map((item: any) => ({
                label: item.name,
                value: item.id,
              }))
            )
          } else {
            setClusterIdOpt(
              res.data.data.clusters
                .filter((item: any) => user?.clusters?.includes(item.id))
                .map((item: any) => ({
                  label: item.name,
                  value: item.id,
                }))
            )
          }
        })
        .catch((e) => {
          toast({
            title: `Não foi possível trazer as opções de filtro:  ${e.response?.data?.message}`,
            status: 'error',
            isClosable: true,
          })
        })
  }, [isOpen, toast, user])

  const getChannelId = useCallback(() => {
    isOpen &&
      api
        .post('/channels/by-cluster', {
          clusters: clusterSelected.map((item: any) => item.value),
        })
        .then((res) => {
          setChannelsOpt(
            res.data?.data?.channels?.map((v: IChannels) => ({
              label: v?.channel_name,
              value: v?.channel_code,
            }))
          )
        })
        .catch((error) => {
          if (!toast.isActive('data')) {
            toast({
              id: 'data',
              title: `Erro ao carregar dados, tente novamente: ${error.message}`,
              status: 'error',
            })
          }
        })
  }, [isOpen, toast, clusterSelected])

  const getClients = useCallback(
    (clients: IOption[]) => {
      if (isOpen) {
        api
          .post('/clients/filter-by-channel-cluster', {
            channelCode: channelsSelected.map((item: IOption) => item.value),
            clusterId: clusterSelected.map((item: IOption) => item.value),
          })
          .then((res) => {
            const result = res.data?.data?.result?.map((v: any) => ({
              label: v?.client_name,
              value: v?.client_code,
            }))
            const filtered = result.filter(
              (item: IOption) =>
                !clients.some((client: IOption) => client.value === item.value)
            )
            setClientsOpt(filtered)
          })
          .catch((error) => {
            if (!toast.isActive('data')) {
              toast({
                id: 'data',
                title: `Erro ao carregar dados, tente novamente: ${error.message}`,
                status: 'error',
              })
            }
          })
      }
    },
    [isOpen, toast, channelsSelected, clusterSelected]
  )

  useEffect(() => {
    if (clusterSelected) {
      getChannelId()
    }
  }, [clusterSelected, getChannelId, clients])

  useEffect(() => {
    if (channelsSelected) {
      getClients(clients)
    }
  }, [channelsSelected, getClients, clients])

  useEffect(() => {
    // getChannelId()
    getClusterId()
  }, [getClusterId])

  const addClient = () => {
    const data = clientsSelected.map(
      (item: { label: string; value: string }) => item.value
    )
    handleAddClient(data)
  }

  const downloadModel = () => {
    const request: { [key: string]: string[] } = {}

    api
      .post(
        'cluster-competitor/download-layout',
        { extensionType: 'xls' },
        { responseType: 'arraybuffer' }
      )
      .then((response) => {
        const mimeType = 'application/vnd.ms-excel;'
        const fileExtension = 'xls'

        // if (extensionType === 'csv') {
        //   mimeType = 'text/csv;charset=utf-8;'
        //   fileExtension = 'csv'
        // }

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: mimeType })
        )

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Modelo Concorrente`)

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((err) => {
        toast({
          title: 'Erro, tente novamente.' + err,
          status: 'error',
          isClosable: true,
        })
      })
  }

  const openLoading = useCallback((message: string, promise: Promise<void>) => {
    loadingRef.current.open(message, promise)
  }, [])

  const handleUpload = useCallback(
    (formData: FormData) => {
      openLoading(
        'Fazendo upload...',
        api
          .post(`/cluster-competitor/upload`, formData)
          .then(() => {
            toast({
              title: 'Upload realizado com sucesso.',
              status: 'success',
            })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
          .catch((error) => {
            toast({
              title: `${error.response.status} - Erro ao fazer upload: verifique o arquivo e tente novamente.`,
              status: 'error',
            })
          })
      )
    },
    [openLoading, toast]
  )

  const onUpload = useCallback(
    (e: any) => {
      const fileName = e.target?.files?.[0]?.name

      if (!fileName || !/\.(xls|xlsx)$/i.test(fileName)) {
        return toast({
          title: 'Arquivo inválido. Por favor envie no formato excel (.xls ou .xlsx).',
          status: 'error',
        });
      }
      const file = e.target.files[0]

      if (file.size >= 4e7) {
        return toast({
          title: 'Arquivo inválido. Limite 40mb.',
          status: 'error',
        })
      }

      const formData = new FormData()

      formData.append('file', file)

      handleUpload(formData)
    },
    [handleUpload, toast]
  )

  return (
    <>
      <Flex mb="1rem">
        <Button
          padding="0.5rem 1rem"
          onClick={onOpen}
          containerStyle={{
            backgroundColor: '#003b74',
            // backgroundColor: '#003b74' || '#38A169',
          }}
        >
          Upload Concorrente
        </Button>
      </Flex>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="35rem">
          <ModalHeader>Upload Concorrente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="flex-start">
              <Text marginBottom={6}>Relação de Concorrentes</Text>

              <Text fontWeight={'bold'}>IMPORTANTE!</Text>
              <Text>Ao fazer o upload o arquivo atual</Text>
              <Text>será sobrescrito.</Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <VStack w="100%" align="flex-start">
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="space-between">
                <Button onClick={onClose}>Cancelar</Button>
                <label htmlFor="upload">
                  <input
                    type="file"
                    id="upload"
                    name="upload"
                    onChange={onUpload}
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement
                      element.value = ''
                    }}
                    style={{ display: 'none' }}
                  />
                  <UploadButton>Carregar Arquivo</UploadButton>
                  <LoadingModal ref={loadingRef} />
                </label>
              </Flex>
              <Divider />
              <Flex w="100%" pt="0.5rem" justifyContent="center">
                <Button width="100%" bg="#2cde8b" onClick={downloadModel}>
                  {isLoading ? (
                    <CircularProgress style={{ color: 'green' }} size={20} />
                  ) : (
                    'Download Planilha Modelo'
                  )}
                </Button>
              </Flex>
            </VStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalUploadCompetitor
